<template>
  <div class="buy">
    <div class="steps" v-if="status <= 2">
      <div class="item">
        <div class="order">
          <el-image
            class="order_img"
            v-if="status == 1"
            :src="require('@as/serve/strict/order_1.png')"
          ></el-image>
          <div class="status_num active" v-else>1</div>
          <div class="order_name" :class="{ active: status >= 1 }">
            确认订单
          </div>
        </div>
      </div>
      <div class="line">
        <el-image
          class="line_img"
          :src="require('@as/serve/strict/line_active.png')"
        ></el-image>
      </div>
      <div class="item">
        <div class="order">
          <el-image
            class="order_img"
            v-if="status == 2"
            :src="require('@as/serve/strict/order_2.png')"
          ></el-image>
          <div class="status_num" v-else>2</div>
          <div class="order_name" :class="{ active: status == 2 }">
            订单支付
          </div>
        </div>
      </div>
      <div class="line">
        <el-image
          class="line_img"
          :src="require('@as/serve/strict/line.png')"
        ></el-image>
      </div>
      <div class="item">
        <div class="order">
          <div class="status_num">3</div>
          <div class="order_name">完成订单</div>
        </div>
      </div>
    </div>
    <template v-if="status == 1">
      <div class="order_container">
        <div class="order_item">
          <div class="info_title">商品信息</div>
          <div class="info">
            <el-image
              class="info_img"
              :src="require('@as/serve/strict/order_1.png')"
            ></el-image>
            <div class="order">
              <div class="name">
                2020众合真金题分类历年真题详解新法律职业资格考试
              </div>
              <div class="size">红色 / 大型套装</div>
            </div>
          </div>
        </div>
        <div class="order_item">
          <div class="info_title">数量</div>
          <div class="info">
            <div class="order">
              <div class="name">1</div>
            </div>
          </div>
        </div>
        <div class="order_item">
          <div class="info_title">价格</div>
          <div class="info">
            <div class="order">
              <div class="name">￥36.00</div>
            </div>
          </div>
        </div>
        <div class="order_item">
          <div class="info_title">运费</div>
          <div class="info">
            <div class="order">
              <div class="name">￥0</div>
            </div>
          </div>
        </div>
      </div>
      <div class="address_container">
        <div class="left">
          <div class="address_title">地址信息</div>
          <div class="row">
            <div class="label">
              <div>联系人</div>
              <div>:</div>
            </div>
            <div class="value">Azz.sui</div>
            <div class="btn_group">
              <div class="btn">修改</div>
              <div class="btn">切换地址</div>
            </div>
          </div>
          <div class="row">
            <div class="label">
              <div>联系方式</div>
              <div>:</div>
            </div>
            <div class="value">186****5376</div>
          </div>
          <div class="row">
            <div class="label">
              <div>收货地址</div>
              <div>:</div>
            </div>
            <div class="value">
              北京市北京市房山区长阳镇首开熙悦汇 * 号楼 ****
            </div>
          </div>
        </div>
        <div class="right">
          <div class="row">
            <div class="label">商品合计:</div>
            <div class="value">￥89.80</div>
          </div>
          <div class="row">
            <div class="label">快递:</div>
            <div class="value">￥0.00</div>
          </div>
          <div class="total_price">
            <div class="label">应付金额:</div>
            <div class="value">￥819.80</div>
          </div>
          <div class="blue_btn" @click="status = 2">立即下单</div>
        </div>
      </div>
    </template>
    <template v-if="status == 2">
      <div class="select_pay_container">
        <div class="pay_title">请选择支付方式</div>
        <div class="pay_subtitle">
          <div class="left">
            <div class="label">订单号:</div>
            <div class="value">20190313152319YXMPDD1624196296</div>
          </div>
          <div class="right">
            <div class="label">实付:</div>
            <div class="value">¥109.80</div>
          </div>
        </div>
        <div class="select_container">
          <el-radio v-model="radio" label="1">
            <el-image
              class="info_img"
              :src="require('@as/serve/strict/zfb.png')"
            ></el-image
          ></el-radio>
          <el-radio v-model="radio" label="2">
            <el-image
              class="info_img"
              :src="require('@as/serve/strict/wx.png')"
            ></el-image
          ></el-radio>
        </div>
        <div class="blue_btn" @click="status = 3">确认支付</div>
      </div>
    </template>
    <template v-if="status == 3">
      <div class="wechat_container">
        <div class="info">
          <div class="pay_title">请及时付款，以便订单尽快处理！</div>
          <div class="pay_subtitle">
            <div class="left">
              <div class="label">订单号:</div>
              <div class="value">20190313152319YXMPDD1624196296</div>
            </div>
            <div class="right">
              <div class="label">实付:</div>
              <div class="value">¥109.80</div>
            </div>
          </div>
        </div>
        <div class="detail">
          <div class="select" @click=";(status = 2), (radio = '1')">
            选择其他方式>>
          </div>
          <div class="code">
            <el-image
              class="code_img"
              :src="require('@as/serve/strict/code.png')"
            ></el-image>
            <div>微信扫一扫支付</div>
          </div>
          <el-image
            class="wechat_img"
            :src="require('@as/serve/strict/wechat.png')"
          ></el-image>
        </div>
      </div>
    </template>
    <div class="float_icon">
      <el-image
        class="icon"
        :src="require('@as/serve/strict/icon_6.png')"
      ></el-image>
      <el-image
        class="icon"
        :src="require('@as/serve/strict/icon_7.png')"
      ></el-image>
      <div class="icon">
        <el-image class="icon" :src="require('@as/serve/strict/cart.png')">
        </el-image>
        <div class="num">3</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: 1,
      radio: '1',
    }
  },
}
</script>

<style lang="less" scoped>
.buy {
  position: relative;
  padding: 38px 38px 100px;
  background: #f5f6fa;

  .steps {
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      .order {
        display: flex;
        align-items: center;
        .order_name {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #cccccc;
          &.active {
            color: #3b519c;
          }
        }
        .order_img {
          margin-right: 12px;
          width: 24px;
          height: 32px;
        }
        .status_num {
          margin-right: 12px;
          padding: 7px 10px;
          background: #dedede;
          border-radius: 50%;
          font-size: 15px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #ffffff;
          &.active {
            padding: 5px 8px;
            color: #40569f;
            border: 2px solid #3b519c;
            background: #f5f6fa;
          }
        }
      }
    }
    .line {
      .line_img {
        margin: 0 20px;
        width: 54px;
        height: 4px;
      }
    }
  }
  .order_container {
    margin: 20px auto;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 1200px;
    box-sizing: border-box;
    background: #ffffff;
    .order_item {
      flex: 1;
      &:first-child {
        .info {
          padding: 20px;
          align-items: center;
        }
      }
      .info_title {
        padding: 0 20px 22px;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        border-bottom: 1px solid #dedede;
      }
      .info {
        padding: 37px 20px;
        height: 120px;
        display: flex;
        box-sizing: border-box;
        .info_img {
          margin-right: 60px;
          width: 80px;
          height: 80px;
        }
        .order {
          .name {
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #333333;
          }
          .size {
            margin-top: 10px;
            min-width: 320px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #999999;
          }
        }
      }
    }
  }
  .address_container {
    margin: 0 auto;
    padding: 41px 32px 36px;
    display: flex;
    justify-content: space-between;
    width: 1200px;
    box-sizing: border-box;
    background: #fff;
    .left {
      flex: 1;
      .address_title {
        margin-bottom: 45px;
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
      .row {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        .label {
          margin-right: 15px;
          display: flex;
          justify-content: space-between;
          width: 65px;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #999999;
        }
        .value {
          min-width: 150px;
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #666666;
        }
        .btn_group {
          display: flex;
          .btn {
            margin-left: 14px;
            padding: 6px 18px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #579ed5;
            border: 1px solid #579ed5;
            border-radius: 2px;
            &:first-child {
              border-color: #9dcbec;
              color: #9ccbec;
            }
          }
        }
      }
    }
    .right {
      width: 320px;
      text-align: right;
      .row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #666666;

        .value {
          width: 188px;
        }
        &:not(:first-child) {
          margin-top: 16px;
          padding-bottom: 60px;
          border-bottom: 1px solid #9dcbed;
        }
      }
      .total_price {
        margin-top: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .label {
          font-size: 14px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #333333;
        }
        .value {
          width: 188px;
          font-size: 24px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #d03434;
        }
      }
    }
  }

  .select_pay_container {
    margin: 20px auto;
    padding: 41px 30px 30px;
    width: 1200px;
    text-align: right;
    background: #fff;
    box-sizing: border-box;

    .select_container {
      margin-top: 34px;
      padding: 49px 20px 41px;
      display: flex;
      align-items: center;
      background: #f5f6fa;
      & /deep/ .el-radio {
        display: flex;
        align-items: center;
      }
      & /deep/ .el-radio__inner::after {
        width: 9px;
        height: 9px;
      }
    }
  }
  .wechat_container {
    margin: 50px auto;
    padding: 30px 30px 40px;
    width: 1200px;
    background: #fff;
    box-sizing: border-box;
    .info {
      padding: 30px 24px 24px;
      background: #f5f6fa;
    }
    .detail {
      margin-top: 58px;
      display: flex;
      align-items: center;
      .select {
        margin-top: auto;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1c9ab1;
        cursor: pointer;
      }
      .code {
        margin-left: 157px;
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
        text-align: center;
        .code_img {
          margin-bottom: 16px;
          padding: 10px;
          width: 140px;
          height: 140px;
          border: 1px solid #dedede;
        }
      }
      .wechat_img {
        margin-left: 120px;
        width: 280px;
        height: 350px;
      }
    }
  }
  .blue_btn {
    margin-top: 30px;
    padding: 16px 68px;
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #ffffff;
    background: #1c9ab1;
    border-radius: 2px;
    display: inline-block;
    cursor: pointer;
  }
  .pay_title {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #333333;
    text-align: left;
  }
  .pay_subtitle {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #999999;
      .label {
        margin-right: 10px;
        color: #999999;
      }
      .value {
        color: #333333;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .label {
        margin-right: 10px;
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #333333;
      }
      .value {
        font-size: 24px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #d03434;
      }
    }
  }
  .float_icon {
    position: absolute;
    bottom: 50px;
    right: 10px;
    padding: 27px 13px;
    background: #fff;
    border-radius: 4px;
    .icon {
      width: 22px;
      height: 22px;
      display: block;
      &:not(:first-child) {
        margin-top: 37px;
      }
      &:nth-child(2) {
        width: 26px;
        height: 20px;
      }
      &:nth-child(3) {
        position: relative;
      }
      .icon {
        width: 24px;
        height: 24px;
      }
      .num {
        position: absolute;
        bottom: 14px;
        left: 18px;
        width: 14px;
        height: 14px;
        line-height: 14px;
        font-size: 10px;
        text-align: center;
        color: #fff;
        background: #3b529c;
        border-radius: 50%;
      }
    }
  }
}
</style>